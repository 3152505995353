<template>
  <div>
    <div class="Section">
      <div class="breadcrumbNav">
        <span class="navName">当前位置 : </span>
        <div class="activeNav">
          <span
            @click="FirstClickFun()">{{ clickVal }} </span
          >
          <b><i class="el-icon-arrow-right" aria-hidden="true"></i></b>
          <ul v-cloak class="checkUl">
            <li
              @click.stop
              v-for="(item, index) in filterTooltip"
              :key="index"
              @click="hideTooltip(item)"
            >
              {{ item.name }} x
            </li>
          </ul>
        </div>
      </div>
      <div class="sortBox">
        <div class="sortUlbox"  v-show="flag">
          <div class="ulTitle">
            一级分类
          </div>
          <ul class="sortUl" >
            <!-- <li class="active">中成药</li><li>西药</li><li>营养保健</li><li>医疗器械</li><li>特惠专区</li><li>宣传物料</li> 		-->
            <li
              v-for="(item, index) in listdata"
              :key="index"
              @click="listdataAdd(item, index)"
              :class="{ active: Boolean(checkFirst === index) }"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="sortUlbox" v-show="flag">
          <div class="ulTitle">
            二级分类
          </div>
          <ul
            class="sortUl"
            v-for="(items, indexs) in listdata"
            :key="indexs"
            v-show="indexs == thisIndex"
          >
            <li
              v-for="(item, index) in items.child"
              :key="index"
              @click="add(item)"
              :class="{ active: item.active == true }"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        
      </div>

      <ul class="filterUl">
        <li
          v-for="(item, index) in filterItems"
          :key="index"
          @click="filterFun(item,index)"
          :class="{ active: Boolean(filterType === index) }"
        >
          {{ item.sort }}
          <i
            :class="
              item.filterMark == 'price' ? Boolean(item.show === 1)?'active el-icon-caret-top  iconTop':'el-icon-caret-top  iconTop':Boolean(filterType === index)?'active el-icon-bottom':'el-icon-bottom'
            "
            aria-hidden="true"
          ></i>
           <i v-if="item.filterMark == 'price'"
            :class="Boolean(item.show === 2)?'active el-icon-caret-bottom iconBottom':'el-icon-caret-bottom iconBottom'"
            aria-hidden="true"
          ></i>
        </li>
        <!-- <li @click="filterFun('sell')"  :class="{'active':item.show===true}">销量 <i class="el-icon-bottom"></i></li>
        <li @click="filterFun('rise')">上架时间 <i class="el-icon-bottom"></i></li>
        <li @click="filterFun('price')">价格 <i class="el-icon-d-caret"></i></li> -->
        <li>
          <el-checkbox v-model="checked" @change="filterFun()"
            >仅显示有货</el-checkbox
          >
        </li>
      </ul>
      <div class="goodsList">
        <shopItem
          v-for="(item, index) in searchProducts"
          :key="index"
          class="goods"
          :info="item"
        ></shopItem>
      </div>
      <div class="pageBox">
        <el-pagination
          background
          layout="prev, pager, next"
          :hide-on-single-page="false"
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// 获取到的一二级分类数据
const shopItem = () => import("components/index/shopItem.vue");
import {ProductcategoryTree,GetProducts} from 'api/product.js';
// import Qs from 'qs'
export default {
  name: "ProductCategory",
  inject:['reload'],
  data() {
    return {
       total: 0,
      currentPage: 1, //当前页
      size: 10, //每页数量
      searchProducts: '', //初始商品数据
      toolTip:JSON.parse(localStorage.getItem('newTooltip')),
      thisIndex: 0, //   二级分类，循环，初始展示值为第一组循环值
      clickVal: localStorage.getItem('clickVal'), //  面包屑 初始，一级分类选中值为空
      clickID: localStorage.getItem('clickID'),    //一级分类ID 
      checkFirst: 0, //    一级分类，tab切换，初始值为0
      flag: true, //   二级分类列表   初始值为展示状态
      Medicareflag: true, //医保分类列表  初始值为展示状态
      checked: false, //筛选checked 选中状态  初始为选中
      filterType:'',
      sort:0,//传给后台的搜索值
      filterItems: [
        { sort: "销量", show: false, filterMark: "sell" },
        { sort: "上架时间", show: false, filterMark: "rise" },
        { sort: "价格",show: 0, filterMark: "price" },
      ],
      listdata: "",   //二级分类数据
      parmesData:{}//初始搜索值传参
    };
  },
  computed: {
    // 计算属性监听  面包屑  过滤条件 返回数据，展示到面包屑中
    filterTooltip: function() {
      var  newTooltip = [];
      if(JSON.parse(localStorage.getItem('newTooltip'))!=null){
          JSON.parse(localStorage.getItem('newTooltip')).forEach((item) => {
            if (item.show == false) {
                this.flag = true;
            }else{
                this.flag = false;
            }
          })
      }else{
        newTooltip = [];
      }
      this.toolTip.forEach((item) => {
        if (item.show != false) {
          newTooltip.push(item);
        }
      });
      return newTooltip;
    },
  },
  created() {
    // 获取分类数据，改变data，分类数据赋值
    ProductcategoryTree().then(res=>{
           this.listdata=res.data.data.child;
           if(localStorage.getItem('clickVal')==null){
              this.clickVal = this.listdata[0].name;
              this.clickID =  this.listdata[0].id;
            }else{
              this.listdata.forEach((item,index)=>{
                if(item.name==localStorage.getItem('clickVal')){
                  this.checkFirst=index
                  this.thisIndex = index;
                }
              })
            }
            if(JSON.parse(localStorage.getItem('newTooltip'))==null){
              this.toolTip=[]
            }
    });
 
    if(this.$route.query.ids){
      if(this.toolTip.length==0){
        this.parmesData={
              productCategoryId:this.clickID,   //"商品分类id
        };
      }else{
        let CategoryType=this.$route.query.ids
        this.parmesData={productCategoryId:CategoryType};
      }
      if(sessionStorage.getItem('current')){
          this.parmesData.current = sessionStorage.getItem('current'); //当前页
          this.currentPage= sessionStorage.getItem('current')
      }else{
          this.parmesData.current = 1; //当前页
      }
      this.parmesData.size = 10; //每页数量
      this.getProducts(this.parmesData)
    }else{
      this.parmesData={
            productCategoryId:this.clickID,   //"商品分类id
      };
      if(sessionStorage.getItem('current')){
          this.parmesData.current = sessionStorage.getItem('current'); //当前页
          this.currentPage= sessionStorage.getItem('current')
      }else{
          this.parmesData.current = 1; //当前页
      }
      this.parmesData.size = 10; //每页数量
        this.getProducts(this.parmesData);
    }
  },

  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.parmesData.current = this.currentPage; //当前页

      sessionStorage.setItem('current',this.currentPage);
      this.parmesData.size = this.size; //每页数量
       // 传递参数
      this.getProducts(this.parmesData);
    },
    getProducts(parmes){
        GetProducts(parmes).then(res=>{
           const records = res.data.data.records;
            this.searchProducts=records
            // console.log(records)
            this.total=Number(res.data.data.total);
        })
    },


    // 点击筛选条件，获取商品数据渲染
    filterFun(item,index) {
      if (item) {
          this.filterType=index
          if(this.filterType===2){
            if(item.show===1){
              item.show = 2;
              this.sort=3
            }else{
              item.show = 1;
              this.sort=index
            }
          }else{
            this.filterItems[2].show=0
            this.sort=index
          }
          // 搜索条件 sort：  0 （销售 从高到低）| 1（上架时间  从近到远）| 2 （价格 从低到高 ）| 3（价格 从高到低 ）
            this.parmesData.sort = this.sort; //搜索条件
            this.getProducts(this.parmesData);
      }else {
        //显示有货
        if (this.checked == false) {
           this.parmesData.isHaveStock = 0; //否
            this.getProducts(this.parmesData);
        } else {
          //显示有货
            this.parmesData.isHaveStock = 1; //是
            this.getProducts(this.parmesData);
        }
      }
    },
    // 一级分类 点击事件
    listdataAdd(lists, index) {
      this.checkFirst = index;
      lists.active = true;
      this.clickVal = lists.name;
       this.clickID =  lists.id;
      this.thisIndex = index;
      this.flag = true;
      localStorage.setItem('clickVal',lists.name);
      localStorage.setItem('clickID',lists.id);

      this.parmesData={
            productCategoryId:lists.id,   //"商品分类id
      };
       this.parmesData.current = 1; //当前页
       sessionStorage.setItem('current',this.parmesData.current);
        this.currentPage= sessionStorage.getItem('current')
      this.parmesData.size = 10; //每页数量
       // 传递参数
      this.getProducts(this.parmesData);
    },



    // 二级分类 点击事件
    add(s) {
      // 隐藏当前点击行
      this.flag = false;
      var toolmsg = { name: s.name, show: true ,id:s.id};
      this.toolTip.push(toolmsg);
      this.parmesData={
            productCategoryId:s.id,   //"商品分类id
      };

        this.parmesData.current = 1; //当前页
     sessionStorage.setItem('current',this.parmesData.current);
     this.currentPage= sessionStorage.getItem('current')
      this.parmesData.size = 10; //每页数量
       // 传递参数
      this.getProducts(this.parmesData);

    },
    // 面包屑 点击删除事件
    hideTooltip(item) {
      if (item.type == 1) {
        this.Medicareflag = true;
      } else {
        this.flag = true;
      }
      item.show = false;
      this.listdata.forEach(function(items) {
        items.child.forEach(function(item) {
          item.active = false;
        });
      });
      this.parmesData={
            productCategoryId:this.clickID,   //"商品分类id
      };
        this.parmesData.current = 1; //当前页
      this.parmesData.size = 10; //每页数量
       // 传递参数
      this.getProducts(this.parmesData);
    },

    // 点击当前位置一级分类
    FirstClickFun(){
      const newTooltip=[];
      localStorage.setItem('newTooltip',JSON.stringify(newTooltip));
      this.reload();
    }
  },
  watch: {
    "$route.query.type": function() {
    },
  },
  components: {
    shopItem,
  },

  mounted() {
  },
   updated() {
    

        this.toolTip.forEach((item,index)=>{
          if(item.show==false){
              if (item.type == 1) {
                this.Medicareflag = true;
              } else {
                  this.flag = true;
              }
            this.toolTip.splice(index,1);
          }
        })
       localStorage.setItem('newTooltip',JSON.stringify(this.toolTip));
   }
};
</script>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ed3129;
  color: #000;
}
/* 上一页，下一页样式 */
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  background: #fff;
  color: #666;
  padding: 2px;
  box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li {
  background: #fff;
  color: #666;
  padding: px;
  box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
  color: #ed3129;
  font-size: 14px;
}
</style>
<style lang="less" scoped>
.pageBox {
  float: left;
  width: 100%;
  .el-pagination {
    padding-top: 40px;
    text-align: center;
    float: right;
  }
}
@import "~style/index.less";
/* 隐藏未编译的变量 */
[v-cloak] {
  display: none;
}
.Section {
  width: 100%;
  float: left;
  margin-top: 10px;
  background: #f6f6f6;
  padding-bottom: 40px;
  overflow: hidden;
  .breadcrumbNav {
    padding: 8px 15px 15px 0;
    margin-bottom: 17px;
    font-size: 13px;
    line-height: 20px;
    .navName {
      float: left;
      color: #999;
      padding-right: 8px;
    }
    .activeNav {
      float: left;
      color: #999;
      line-height: 20px;
      span {
        color: #551a8b;
        padding-right: 8px;
        float: left;
         cursor: pointer;
      }
      b {
        float: left;
      }
      .checkUl {
        float: left;
        padding-left: 8px;
        li {
          padding: 0 5px;
          height: 20px;
          line-height: 18px;
          border: 1px solid #ffe76f;
          color: #ffe76f;
          font-size: 12px;
          background: #fff;
          margin-right: 8px;
          float: left;
          cursor: pointer;
        }
      }
    }
  }
  .sortBox {
    box-sizing: content-box;
    background-color: #ffffff;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    width: 100%;
    float: left;
    .sortUlbox:last-child{
      border-bottom: none;
    }
    .sortUlbox {
      width: 100%;
      padding: 8px 15px;
      float: left;
      line-height: 40px;
      border-bottom:1px dashed #ccc;
      .ulTitle {
        font-size: 14px;
        font-weight: bold;
        color: #666666;
        width: 136px;
        text-align: center;
        float: left;
      }
      .sortUl {
        width: calc(100% - 136px);
        float: left;
        li {
          float: left;
          margin-right: 40px;
          color: #999;
          font-size: 14px;
          font-weight: bold;
           cursor: pointer;
            &:hover{
                color: #FFD303;
            }
        }
        li.active {
          color: #FFD303;
        }
      }
    }
  }

  .filterUl {
    padding: 8px 15px;
    margin-bottom: 17px;
    border: 1px solid #eeeeee;
    box-sizing: content-box;
    background-color: #ffffff;
    height: 20px;
    line-height: 20px;
    width: 100%;
    float: left;
    
    li.active {
      color: #ffe76f;
    }
    li {
      float: left;
      margin-right: 40px;
      color: #666666;
      font-size: 12px;
       cursor: pointer;
            &:hover{
                color: #FFD303;
                  .el-checkbox{
                  color: #FFD303;
                }
            }
      i {
        font-weight: bolder;
        font-size: 14px;
      }
    }
  }
  .goodsList {
    height: auto;
    // overflow: hidden;
    float: left;
    min-height: 228px;
    background: #f6f6f6;
    .goods:nth-child(5n + 0) {
      margin-right: 0px;
    }
  }
}
</style>
